<template>
    <div class="card">
        <!-- @slot Main content -->
        <slot name="default" />

        <hr class="my-2" />

        <!-- @slot Footer contant -->
        <slot name="footer" />
    </div>
</template>

<style scoped>
.card {
    @apply border p-5 pb-3 bg-white;
    box-shadow: 0 3px 8px #00000008;
    border-radius: 12px;
}
</style>
