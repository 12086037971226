export const PASS_STATE_ACTIVE = 'ACTIVE';
export const PASS_STATE_SECRET_VERIFY = 'SECRET_VERIFY';
export const PASS_STATE_SEND_OTP = 'SEND_OTP';
export const PASS_STATE_OTP_VERIFY = 'OTP_VERIFY';
export const PASS_STATE_INACTIVE = 'INACTIVE';
export const PASS_STATE_VERIFIED = 'VERIFIED';
export const PASS_STATE_VERIFICATION_FAILED = 'VERIFICATION_FAILED';
export const PASS_STATE_WAITING_APPROVE = 'WAITING_APPROVE';

export const PASS_VERIFICATION_STEP_HOST_SECRET = 'SECRET';
export const PASS_VERIFICATION_STEP_OTP = 'OTP';

export const PASS_VERIFICATION_RESULT_OK = 'OK';
export const PASS_VERIFICATION_RESULT_FAILED = 'FAILED';

export const DEVICE_STATE_PRISTINE = 'PRISTINE';
export const DEVICE_STATE_REQUEST_SENT = 'SENT';
export const DEVICE_STATE_OPENED = 'OPENED';
export const DEVICE_STATE_ERROR = 'ERROR';
export const DEVICE_STATE_FAILED = 'FAILED';

export const PASS_ERROR_CODE = {
    NOT_STARTED_YET: 'pass.not_started_yet',
    NOT_FOUND: 'pass.not_found',
    NOT_AVAILABLE: 'pass.not_available',
    EXPIRED: 'pass.expired',
    HAS_NEW_PASS: 'pass.has_new_pass',
};

export const LOCK_VENDORS = {
    PDK: 'PDK',
    YALE: 'YALE',
    ALLEGION: 'ALLEGION',
    BRIVO: 'BRIVO',
};

export const PASS_TYPES = {
    QUEXT: 'QUEXT',
    VENDOR: 'VENDOR',
    GUEST: 'GUEST',
    RESIDENT: 'RESIDENT',
};
