<template>
    <Form
        class="h-full pt-6"
        :submit="handleSubmit"
        :initial-values="{ code: null }"
    >
        <div class="flex flex-col w-full h-full">
            <div class="flex-grow overflow-auto">
                <div class="page-title mb-6">
                    {{ t('pass.use_host_secret') }}
                </div>

                <div class="infobox flex items-baseline mb-6">
                    <Icon
                        name="info"
                        class="w-4 h-4 mr-4"
                    />
                    <span class="flex-1">
                        {{ t('pass.host_secret_notice') }}
                    </span>
                </div>

                <TextField
                    name="code"
                    :label="t('pass.host_secret') + ' *'"
                    :validate="required"
                    input-mode="numeric"
                />
            </div>

            <div class="footer-buttons w-full flex flex-col pt-6">
                <button
                    class="btn btn-primary mb-3"
                    type="submit"
                >
                    {{ t('pass.enter') }}
                </button>
            </div>
        </div>
    </Form>
    <Loader
        :loading="loading"
        backdrop
    />
</template>

<script>
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader.vue';
import {
    PASS_VERIFICATION_RESULT_FAILED,
    PASS_VERIFICATION_RESULT_OK,
    PASS_VERIFICATION_STEP_HOST_SECRET,
    PASS_ERROR_CODE,
} from '../../constants/passes';
import Icon from '@/components/ui/Icon.vue';
import { useI18n } from 'vue-i18n';
import { mapActions } from 'vuex';

export default {
    components: { Icon, Loader, TextField, Form },

    mixins: [ValidatorMixin, NotifyMixin],

    emits: ['verified', 'failed'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        ...mapActions({
            setAccessToken: 'pass/setAccessToken',
        }),

        async handleSubmit(values) {
            this.loading = true;

            try {
                const { result, accessToken = '' } = await this.$passDataProvider.verify('passes', {
                    data: {
                        type: PASS_VERIFICATION_STEP_HOST_SECRET,
                        value: values.code,
                    },
                });

                if (result === PASS_VERIFICATION_RESULT_OK) {
                    // To lock/unlock any lock it requires "accessToken"
                    if (accessToken) {
                        this.setAccessToken(accessToken);
                    }
                    this.$emit('verified');
                } else if (result === PASS_VERIFICATION_RESULT_FAILED) {
                    return {
                        code: this.t('pass.host_secret_verification_failed_notice'),
                    };
                }
            } catch (error) {
                if (error.data.appErrorCode === PASS_ERROR_CODE.NOT_FOUND) {
                    this.$emit('failed', error);
                } else {
                    this.notifyError(error.message);
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
