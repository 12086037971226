import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions({
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
        }),
    },
};
