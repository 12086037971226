<template>
    <div
        v-if="isError"
        class="field-error"
    >
        {{ message }}
    </div>
</template>

<script>
import { computed, onUnmounted } from 'vue';
import { useField } from 'vue-final-form';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        showErrorImmediately: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const { fieldState, unregister } = useField(props);

        onUnmounted(unregister);

        const isError = computed(
            () =>
                (fieldState.value.error && (fieldState.value.touched || props.showErrorImmediately)) ||
                (fieldState.value.submitError && !fieldState.value.dirtySinceLastSubmit)
        );

        const message = computed(() => fieldState.value.error || fieldState.value.submitError);

        return {
            isError,
            message,
        };
    },
};
</script>
