<template>
    <div
        v-if="phoneNumber && isSelfTourType"
        :class="`call-us-${position}`"
    >
        <div
            class="call-us__label"
            :class="`call-us__label--${position}`"
        >
            {{ t('pass.anyIssues') }}
        </div>
        <a
            :href="phoneURI"
            class="call-us__btn"
        >
            <Icon
                name="phone"
                class="mr-2"
            />
            <span class="mt-1 font-ffdin text-md uppercase font-bold text-purple-600">
                {{ t('pass.callUs') }}
            </span>
        </a>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import Icon from '@/components/ui/Icon';
import { mapGetters } from 'vuex';
import parsePhoneNumber from 'libphonenumber-js';
import { PASS_TYPES } from '../../../constants/passes';

export default {
    name: 'CallUs',

    components: {
        Icon,
    },

    props: {
        error: {
            type: Object,
            default: null,
        },

        position: {
            type: String,
            default: 'top',
        },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    computed: {
        ...mapGetters({
            propertyPhoneNumber: 'pass/propertyPhoneNumber',
            passType: 'pass/passType',
        }),

        phoneNumber() {
            if (this.error) {
                return this.error?.propertyPhoneNumber;
            }

            return this.propertyPhoneNumber;
        },

        formattedPropertyPhoneNumber() {
            return this.phoneNumber.startsWith('+') ? this.phoneNumber : `+${this.phoneNumber}`;
        },

        phoneURI() {
            const phoneNumber = parsePhoneNumber(this.formattedPropertyPhoneNumber);
            return phoneNumber && phoneNumber.getURI();
        },

        isSelfTourType() {
            const selfTourPassTypes = [PASS_TYPES.GUEST, PASS_TYPES.RESIDENT, PASS_TYPES.VENDOR];

            if (this.error) {
                return this.error?.passType && selfTourPassTypes.includes(this.error?.passType);
            }

            return this.passType && selfTourPassTypes.includes(this.passType);
        },
    },
};
</script>

<style scoped>
.call-us-top {
    @apply relative flex items-center bg-gray-100 border-b -mx-6;
    padding: 0.75rem 1rem;
}

.call-us-bottom {
    @apply flex flex-col items-center absolute bottom-0 w-full;
    padding: 1rem 1rem 1.5rem 1rem;
}

.call-us__label {
    @apply font-sofia font-normal text-gray-500 text-md w-full;
    line-height: 20px;
    font-weight: 300;
}

.call-us__label--bottom {
    @apply text-center mb-2;
}

.call-us__label--top {
    max-width: 100px;
}

.call-us__btn {
    @apply flex items-center border border-purple-600 justify-center rounded-xl w-full;
    height: 40px;
}
</style>
