<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div :class="{ 'form-field': !naked }">
                <label
                    v-if="label"
                    class="label"
                >{{ label }}</label
                >
                <div class="relative">
                    <div v-if="!editMode">{{ textValue(props.value) }}</div>
                    <textarea
                        v-if="editMode && multiline"
                        v-bind="$attrs"
                        :data-test="name"
                        :type="inputType"
                        :inputmode="inputMode"
                        class="input"
                        :name="props.name"
                        :disabled="disabled"
                        :value="format(props.value)"
                        :class="{ error: props.meta.error && (props.meta.touched || showErrorImmediately) }"
                        v-on="fieldEvents"
                    />
                    <input
                        v-if="editMode && !multiline"
                        :data-test="name"
                        :type="inputType"
                        :inputmode="inputMode"
                        class="input"
                        :name="props.name"
                        :disabled="disabled"
                        :value="format(props.value)"
                        :class="{ error: props.meta.error && (props.meta.touched || showErrorImmediately) }"
                        v-on="fieldEvents"
                    />
                    <button
                        v-if="type === 'password'"
                        type="button"
                        class="absolute right-0 top-0 bottom-0 p-3 focus:outline-none"
                        @click="togglePassword"
                    >
                        <icon
                            :name="showPassword ? 'eye-off' : 'eye'"
                            class="eye-icon"
                        />
                    </button>
                </div>
                <div
                    v-if="helperText"
                    class="field-hint"
                >
                    {{ helperText }}
                </div>

                <FieldError
                    :name="name"
                    :show-error-immediately="showErrorImmediately"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import Icon from '@/components/ui/Icon';
import FieldError from '@/components/form/FieldError';

export default {
    name: 'TextField',
    components: {
        FieldError,
        FinalField,
        Icon,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        naked: {
            type: Boolean,
            default: false,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        multiline: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        editMode: {
            type: Boolean,
            default: true,
        },

        format: {
            type: Function,
            default: val => val,
        },

        parse: {
            type: Function,
            default: val => val,
        },

        helperText: {
            type: String,
        },

        type: {
            type: String,
            default: 'text',
        },

        inputMode: {
            type: String,
            default: 'text',
        },

        showErrorImmediately: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            showPassword: false,
        };
    },

    computed: {
        fieldEvents() {
            return {
                input: e => this.$refs.field.fieldState.change(this.parse(e.target.value)),
                blur: () => this.$refs.field.fieldState.blur(),
                focus: () => this.$refs.field.fieldState.focus(),
            };
        },

        inputType() {
            return this.type === 'password' && this.showPassword ? 'text' : this.type;
        },
    },

    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
        },

        textValue(value) {
            return value ?? '-';
        },
    },
};
</script>

<style>
.eye-icon {
    @apply w-5 h-5 text-gray-500;
}
</style>
