<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div :class="{ 'form-field': !naked }">
                <label class="label">{{ label }}</label>
                <div v-if="!editMode">{{ formatValue(props.value) }}</div>
                <div
                    v-if="editMode"
                    class="flex"
                >
                    <div class="w-36">
                        <SelectInput
                            class="code-select"
                            :options="countries"
                            :modelValue="country"
                            :clearable="false"
                            :disabled="disabled || isCountriesDisabled"
                            :adaptive="false"
                            object-mode
                            @update:modelValue="handleDropdownInput"
                        />
                    </div>
                    <div class="flex-auto">
                        <the-mask
                            :data-test="name"
                            type="text"
                            class="input border-l-0 rounded-l-none"
                            mask="(###) ###-####"
                            :value="number"
                            :name="props.name"
                            :disabled="disabled"
                            :class="{ error: props.meta.error && props.meta.touched }"
                            inputmode="tel"
                            @input="handleNumberChange"
                            v-on="fieldEvents"
                        />
                    </div>
                </div>

                <FieldError :name="name" />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { TheMask } from 'vue-the-mask';
import { useCountryCodes } from '@/composable/useCountryCodes';
import SelectInput from '@/components/ui/SelectInput';
import FieldError from '@/components/form/FieldError';

export default {
    components: {
        FinalField,
        TheMask,
        SelectInput,
        FieldError,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        naked: {
            type: Boolean,
            default: false,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        isCountriesDisabled: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        editMode: {
            type: Boolean,
            default: true,
        },
    },

    setup() {
        const countries = useCountryCodes();

        return {
            countries: countries.map(({ prefix: key, dialCode }) => ({ key, value: `${key} (+${dialCode})`, dialCode })),
        };
    },

    data() {
        return {
            code: 'US',
            number: undefined,
            initialized: false,
        };
    },

    computed: {
        fieldEvents() {
            return {
                blur: () => this.$refs.field.fieldState.blur(),
                focus: () => this.$refs.field.fieldState.focus(),
            };
        },

        country() {
            return this.countries.find(country => country.key === this.code);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.getValueFromFieldState();
            this.initialized = true;
        });

        this.$watch(
            () => [this.number, this.code],
            () => {
                this.$refs.field.fieldState.change(`+${this.country?.dialCode + this.number}`);
            }
        );

        this.$watch('$refs.field.fieldState.value', () => {
            this.getValueFromFieldState();
        });
    },

    methods: {
        getValueFromFieldState() {
            const fieldValue = this.$refs.field.fieldState.value;

            if (fieldValue) {
                const phoneNumber = parsePhoneNumberFromString(fieldValue);

                if (phoneNumber?.country) {
                    this.code = phoneNumber.country;
                }

                if (phoneNumber?.nationalNumber) {
                    this.number = phoneNumber.nationalNumber;
                }
            }
        },

        formatValue(value) {
            return parsePhoneNumberFromString(value)?.formatNational() ?? '-';
        },

        handleDropdownInput(val) {
            if (this.initialized) {
                this.code = val.key;
            }
        },

        handleNumberChange(value) {
            if (typeof value !== 'string') {
                return;
            }

            this.number = value;
        },
    },
};
</script>

<style scoped>
.code-select :deep(.vs__dropdown-toggle) {
    @apply rounded-r-none;
}
</style>
