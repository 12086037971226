import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { get } from 'lodash-es';
import { i18n } from '@/i18n';

export default {
    methods: {
        composeValidators:
            (...validators) =>
                (value, values, meta) =>
                    validators.reduce((acc, validator) => acc || validator(value, values, meta), undefined),
        triggerValidator: (triggerField, triggerValue, validator) => (value, values, meta) =>
            get(values, triggerField) === triggerValue ? validator(value, values, meta) : undefined,
        required: value => (value || typeof value === 'number' ? undefined : i18n.global.t('validation.required')),
        notEmpty: value => (value && value.length > 0 ? undefined : i18n.global.t('validation.required')),
        email: value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? i18n.global.t('validation.email') : undefined),
        minValue: min => value => !isNaN(value) && value >= min ? undefined : i18n.global.t('validation.min_value', { min }),
        maxValue: max => value => !isNaN(value) && value <= max ? undefined : i18n.global.t('validation.max_value', { max }),
        maxLength: max => value => value && value.length > max ? i18n.global.t('validation.max_length', { max }) : undefined,
        minLength: min => value => value && value.length < min ? i18n.global.t('validation.min_length', { min }) : undefined,
        maxAttachmentsNumber: max => atts =>
            atts && atts.length > max ? i18n.global.t('validation.max_attachments_number', { max }) : undefined,
        minAttachmentsNumber: min => atts =>
            atts && atts.length < min ? i18n.global.t('validation.min_attachments_number', { min }) : undefined,
        alphaNumeric: value => (value && /[^a-zA-Z0-9 ]/i.test(value) ? i18n.global.t('validation.alphanumeric') : undefined),
        phoneNumber: value => (value && !parsePhoneNumberFromString(value)?.isPossible() ? i18n.global.t('validation.phone') : undefined),
        usPhoneNumber: value => (value && !/^(\+1[0-9]{10})$/i.test(value) ? i18n.global.t('validation.us_phone') : undefined),
        noSpaces: value => (value && /\s/.test(value) ? i18n.global.t('validation.no_spaces') : undefined),
        oneTrueRequired: groupName => (value, values) => {
            if (!values[groupName]) {
                return i18n.global.t('validation.selected');
            }

            let oneTrue = false;

            for (const val of Object.values(values[groupName])) {
                if (val) {
                    oneTrue = true;
                }
            }

            return oneTrue ? undefined : i18n.global.t('validation.selected');
        },
    },
};
