<template>
    <div
        v-if="loading"
        class="loader-container"
        :class="{ backdrop: backdrop }"
    >
        <BeatLoader :loading="loading" />
    </div>
</template>

<script>
import BeatLoader from '@/components/ui/BeatLoader';

export default {
    name: 'Loader',
    components: { BeatLoader },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },

        backdrop: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.loader-container {
    @apply w-full h-full absolute flex justify-center items-center z-50 inset-0;
}

.backdrop {
    backdrop-filter: blur(1px);
}
</style>
