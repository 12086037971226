<template>
    <div class="flex flex-col items-center justify-center h-full">
        <PassIcon :passType="passType" />

        <div class="font-ffdin font-bold text-base uppercase mb-3">
            {{ title }}
        </div>

        <div class="font-sofia font-normal text-md text-gray-500 text-center mb-6">
            {{ description }}
        </div>

        <button
            class="btn btn-primary w-full"
            @click="$emit('startClicked')"
        >
            {{ t('app.start') }}
        </button>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { mapGetters } from 'vuex';
import { PASS_TYPES } from '../../constants/passes';
import PassIcon from '../../components/pass/common/PassIcon';

export default {
    components: { PassIcon },

    emits: ['startClicked'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    computed: {
        ...mapGetters({
            passType: 'pass/passType',
            apartmentName: 'pass/apartmentName',
        }),

        title() {
            switch (this.passType) {
            case PASS_TYPES.VENDOR:
            case PASS_TYPES.RESIDENT:
                return this.t('pass.welcome_vendor', { apartmentName: this.apartmentName });

            default:
                return this.t('pass.welcome');
            }
        },

        description() {
            switch (this.passType) {
            case PASS_TYPES.VENDOR:
            case PASS_TYPES.RESIDENT:
                return this.t('pass.no_verify_greeting_vendor');

            default:
                return this.t('pass.no_verify_greeting');
            }
        },
    },
};
</script>
