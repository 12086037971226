<template>
    <icon
        :name="iconName"
        :class="iconClass"
    />
</template>

<script>
import Icon from '@/components/ui/Icon';
import { PASS_TYPES } from '../../../constants/passes';

export default {
    name: 'PassIcon',
    components: { Icon },
    props: {
        classes: {
            type: String,
            default: '',
        },

        passType: {
            type: String,
            default: '',
        },
    },

    computed: {
        iconName() {
            switch (this.passType) {
            case PASS_TYPES.VENDOR:
            case PASS_TYPES.RESIDENT:
                return 'pass-apartment';

            default:
                return 'pass-future';
            }
        },

        iconClass() {
            return `mb-8 ${this.classes}`;
        },
    },
};
</script>
