<template>
    <div class="flex flex-col w-full h-full pt-6">
        <div class="flex-grow overflow-auto">
            <div class="page-title mb-6">
                {{ t('pass.appprovePass') }}
            </div>

            <div class="infobox flex items-baseline mb-6">
                <Icon
                    name="info"
                    class="w-4 h-4 mr-4"
                />
                <span class="flex-1 whitespace-pre-wrap">
                    {{ t('pass.approveNotice') }}
                </span>
            </div>
        </div>

        <div
            v-if="showRefreshButton"
            class="footer-pinned"
        >
            <button
                class="btn btn-primary w-full"
                @click="handleRefresh"
            >
                {{ t('pass.refresh') }}
            </button>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { useI18n } from 'vue-i18n';
import { PASS_STATE_ACTIVE, PASS_STATE_VERIFIED } from '../../constants/passes';

export default {
    name: 'ApprovePass',

    components: { Icon },

    emits: ['approved', 'failed'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            pollingIntervalId: undefined,
            pollingCallsCount: 0,
            maxAttempt: 4,
            intervalSeconds: 5,
            showRefreshButton: false,
        };
    },

    mounted() {
        this.startPolling();
    },

    methods: {
        async handleRefresh() {
            try {
                const response = await this.$passDataProvider.getOne('passes');

                if (response.state === PASS_STATE_ACTIVE || response.state === PASS_STATE_VERIFIED) {
                    clearInterval(this.pollingIntervalId);
                    this.showRefreshButton = false;
                    this.$emit('approved', response);
                }

                // If we've reached maximum attempt calls, clear the interval
                if (this.pollingCallsCount >= this.maxAttempt) {
                    clearInterval(this.pollingIntervalId);
                    this.showRefreshButton = true;
                } else {
                    this.pollingCallsCount++;
                }
            } catch (error) {
                clearInterval(this.pollingIntervalId);
                this.$emit('failed', error);
            }
        },

        startPolling() {
            // Ensure no previous interval is running
            if (this.pollingIntervalId) {
                clearInterval(this.pollingIntervalId);
            }

            this.pollingIntervalId = setInterval(() => {
                this.handleRefresh();
            }, this.intervalSeconds * 1000);
        },
    },
};
</script>
