<template>
    <Form
        v-if="state === PASS_STATE_SEND_OTP"
        class="h-full pt-6"
        :submit="handleNumberSubmit"
    >
        <div class="flex flex-col w-full h-full">
            <div class="flex-grow overflow-auto">
                <div class="page-title mb-6">
                    {{ t('pass.sms_code_header') }}
                </div>

                <div class="infobox flex items-baseline mb-6">
                    <Icon
                        name="info"
                        class="w-4 h-4 mr-4"
                    />
                    <span class="flex-1 whitespace-pre-wrap">
                        {{ t('pass.sms_code_notice') }}
                    </span>
                </div>

                <PhoneField
                    name="phone"
                    :label="t('pass.mobile_phone')"
                    :validate="[required, phoneNumber]"
                />
            </div>

            <div class="footer-pinned">
                <button
                    class="btn btn-primary w-full"
                    type="submit"
                >
                    {{ t('pass.send_otp') }}
                </button>
            </div>
        </div>
    </Form>
    <Form
        v-else-if="state === PASS_STATE_OTP_VERIFY"
        class="h-full pt-6"
        :submit="handleCodeSubmit"
    >
        <div class="flex flex-col w-full h-full">
            <div class="flex-grow overflow-auto">
                <div class="page-title flex items-center mb-6">
                    <Icon
                        v-if="!isSelfTour"
                        class="w-6 h-6 text-black mr-3"
                        name="arrow-left"
                        @click="backToPhone"
                    />
                    <span>
                        {{ t('pass.use_verification_code') }}
                    </span>
                </div>

                <div class="infobox flex items-baseline mb-6">
                    <icon
                        name="info"
                        class="w-4 h-4 mr-4"
                    />
                    <span class="flex-1">
                        {{ otpSentNoticeLabel }}
                    </span>
                </div>

                <TextField
                    name="code"
                    :label="t('pass.otp') + ' *'"
                    input-mode="numeric"
                    :validate="required"
                />
            </div>

            <div class="footer-pinned">
                <button
                    class="btn btn-primary mb-3 w-full"
                    type="submit"
                    :disabled="attemptsLeft === 0"
                >
                    {{ t('pass.verify') }}
                </button>
                <button
                    v-if="attemptsLeft !== 0"
                    class="btn btn-tertiary w-full"
                    type="button"
                    :disabled="resendCountdown > 0"
                    @click="resendCode"
                >
                    {{ t('pass.resend_in', resendCountdown) }}
                </button>
            </div>
        </div>
    </Form>

    <loader
        :loading="loading"
        backdrop
    />
</template>

<script>
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import Icon from '@/components/ui/Icon';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import PhoneField from '@/components/form/PhoneField';
import Loader from '@/components/ui/Loader';
import {
    PASS_STATE_OTP_VERIFY,
    PASS_STATE_SEND_OTP,
    PASS_VERIFICATION_RESULT_FAILED,
    PASS_VERIFICATION_RESULT_OK,
    PASS_VERIFICATION_STEP_OTP,
    PASS_TYPES,
} from '../../constants/passes';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapActions, mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
    components: { Loader, PhoneField, Icon, TextField, Form },

    mixins: [ValidatorMixin, NotifyMixin],

    props: {
        state: {
            type: String,
            required: true,
        },
    },

    emits: ['verified', 'failed', 'update:state'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            PASS_STATE_SEND_OTP,
            PASS_STATE_OTP_VERIFY,
            loading: false,
            resendCountdown: 0,
            countdownIntervalId: undefined,
            attemptsLeft: 5,
        };
    },

    computed: {
        ...mapGetters({
            currentOtpPhone: 'pass/currentOtpPhone',
            passType: 'pass/passType',
        }),

        isSelfTour() {
            return this.passType && this.passType === PASS_TYPES.GUEST;
        },

        otpSentNoticeLabel() {
            switch (this.passType) {
            case PASS_TYPES.GUEST:
                return this.t('pass.otp_self_tour_sent_notice', { currentOtpPhone: this.currentOtpPhone });

            default:
                return this.t('pass.otp_sent_notice');
            }
        },
    },

    mounted() {
        if (this.currentOtpPhone) {
            this.requestOtp(this.currentOtpPhone);
        }

        if (this.state === PASS_STATE_OTP_VERIFY) {
            this.restartCountdown();
        }
    },

    methods: {
        ...mapActions({
            setCurrentPassOtpPhone: 'pass/setCurrentPassOtpPhone',
            setAccessToken: 'pass/setAccessToken',
        }),

        restartCountdown() {
            this.resendCountdown = 60;
            this.countdownIntervalId = setInterval(() => {
                this.resendCountdown -= 1;
                if (this.resendCountdown === 0) {
                    clearInterval(this.countdownIntervalId);
                }
            }, 1000);
        },

        resendCode() {
            this.requestOtp(this.currentOtpPhone);
        },

        handleNumberSubmit(values) {
            this.setCurrentPassOtpPhone(values.phone);
            this.requestOtp(values.phone);
        },

        backToPhone() {
            clearInterval(this.countdownIntervalId);
            this.$emit('update:state', PASS_STATE_SEND_OTP);
        },

        requestOtp(phoneNumber) {
            this.loading = true;
            this.$passDataProvider
                .sendOtp('passes', {
                    data: {
                        phoneNumber,
                    },
                })
                .then(() => {
                    if (this.state === PASS_STATE_SEND_OTP) {
                        this.$emit('update:state', PASS_STATE_OTP_VERIFY);
                    }
                    this.restartCountdown();
                })
                .catch(error => {
                    this.$emit('failed', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async handleCodeSubmit(values) {
            this.loading = true;
            try {
                const {
                    result,
                    attemptsLeft,
                    accessToken = '',
                } = await this.$passDataProvider.verify('passes', {
                    data: {
                        type: PASS_VERIFICATION_STEP_OTP,
                        value: values.code,
                    },
                });

                if (result === PASS_VERIFICATION_RESULT_OK) {
                    // To lock/unlock any lock it requires "accessToken"

                    if (accessToken) {
                        this.setAccessToken(accessToken);
                    }
                    this.$emit('verified');
                } else if (result === PASS_VERIFICATION_RESULT_FAILED) {
                    this.attemptsLeft = attemptsLeft;
                    return {
                        code: this.t('pass.otp_verification_failed_notice', this.attemptsLeft),
                    };
                }
            } catch (error) {
                this.$emit('failed', error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.footer-pinned .btn + .btn {
    @apply mx-0;
}
</style>
