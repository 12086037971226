<template>
    <template v-if="pass">
        <div class="flex flex-col h-full">
            <CallUs v-if="pass.state !== PASS_STATE_INACTIVE" />

            <InactivePass
                v-if="pass.state === PASS_STATE_INACTIVE"
                :error="pass.error"
            />

            <HostSecretVerification
                v-else-if="pass.state === PASS_STATE_SECRET_VERIFY"
                @verified="handleVerificationSuccess"
                @failed="errorHandler"
            />

            <OtpVerification
                v-else-if="pass.state === PASS_STATE_OTP_VERIFY || pass.state === PASS_STATE_SEND_OTP"
                v-model:state="pass.state"
                @verified="handleVerificationSuccess"
                @failed="errorHandler"
            />

            <ApprovePass
                v-if="pass.state === PASS_STATE_WAITING_APPROVE"
                @approved="handleApprove"
                @failed="errorHandler"
            />

            <template v-else-if="pass.state === PASS_STATE_ACTIVE || pass.state === PASS_STATE_VERIFIED">
                <FreePassGreeting
                    v-if="pass.showGreeting"
                    @startClicked="pass.showGreeting = false"
                />
                <ActivePass
                    v-else
                    :pass="pass.data"
                    @reload="loadPassData"
                />
            </template>
        </div>
    </template>

    <loader
        :loading="loading"
        backdrop
    />
</template>

<script>
import InactivePass from '../../components/pass/InactivePass.vue';
import HostSecretVerification from '../../components/pass/HostSecretVerification';
import OtpVerification from '../../components/pass/OtpVerification';
import ActivePass from '../../components/pass/ActivePass';
import ApprovePass from '../../components/pass/ApprovePass';
import Loader from '@/components/ui/Loader';
import FreePassGreeting from '../../components/pass/FreePassGreeting';
import { mapActions, mapGetters } from 'vuex';
import NotifyMixin from '@/mixins/NotifyMixin';
import {
    PASS_STATE_ACTIVE,
    PASS_STATE_INACTIVE,
    PASS_STATE_OTP_VERIFY,
    PASS_STATE_SECRET_VERIFY,
    PASS_STATE_SEND_OTP,
    PASS_STATE_VERIFICATION_FAILED,
    PASS_STATE_VERIFIED,
    PASS_ERROR_CODE,
    PASS_STATE_WAITING_APPROVE,
} from '../../constants/passes';
import { useI18n } from 'vue-i18n';
import CallUs from '../../components/pass/common/CallUs';

export default {
    name: 'Index',

    components: { FreePassGreeting, Loader, HostSecretVerification, ActivePass, OtpVerification, InactivePass, CallUs, ApprovePass },

    mixins: [NotifyMixin],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            PASS_STATE_ACTIVE,
            PASS_STATE_SECRET_VERIFY,
            PASS_STATE_SEND_OTP,
            PASS_STATE_OTP_VERIFY,
            PASS_STATE_INACTIVE,
            PASS_STATE_VERIFIED,
            PASS_STATE_VERIFICATION_FAILED,
            PASS_STATE_WAITING_APPROVE,

            pass: undefined,
            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            getPassByUri: 'pass/getPassByUri',
            currentToken: 'pass/currentToken',
        }),

        passUri() {
            return this.$route.params.id;
        },
    },

    async mounted() {
        this.loading = true;

        try {
            await this.initializePassData();
            await this.loadPassData();

            if (this.pass.state === PASS_STATE_ACTIVE || this.pass.state === PASS_STATE_VERIFIED) {
                this.pass.showGreeting = true;
            }
        } catch (error) {
            this.errorHandler(error);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions({
            setPassData: 'pass/setPassData',
            setCurrentToken: 'pass/setCurrentToken',
            setAccessToken: 'pass/setAccessToken',
            setCurrentPassOtpPhone: 'pass/setCurrentPassOtpPhone',
            clearCurrentToken: 'pass/clearCurrentToken',
        }),

        async initializePassData() {
            // first of all, set correct value for current token. It will be used in all further requests
            const passToken = this.getPassByUri(this.passUri)?.token;
            if (!passToken) {
                // if we haven't started session for this pass
                const {
                    token,
                    passType,
                    propertyDetails = {},
                    accessToken = '',
                    passPhoneNumber = '',
                } = await this.$passDataProvider.startWebSession('passes', {
                    data: {
                        webGuid: this.passUri,
                    },
                });

                this.setPassData({
                    passUri: this.passUri,
                    timezone: propertyDetails.localTimeZone,
                    token,
                    passType,
                    ...propertyDetails,
                });

                this.setCurrentToken(token);

                // For "GUEST" pass types, the user doesn't need to fill the phone number
                if (passPhoneNumber) {
                    this.setCurrentPassOtpPhone(passPhoneNumber);
                }

                // To lock/unlock any lock it requires "accessToken"
                if (accessToken) {
                    this.setAccessToken(accessToken);
                }
            } else if (this.currentToken !== passToken) {
                // if we have token, but it is not current (e.g. not last one used)
                this.setCurrentToken(passToken);
            }
        },

        async loadPassData() {
            try {
                this.pass = await this.$passDataProvider.getOne('passes');
            } catch (error) {
                this.errorHandler(error);
            }
        },

        errorHandler(error) {
            const inactiveErrorCodes = [
                PASS_ERROR_CODE.EXPIRED,
                PASS_ERROR_CODE.NOT_AVAILABLE,
                PASS_ERROR_CODE.NOT_FOUND,
                PASS_ERROR_CODE.NOT_STARTED_YET,
            ];

            if (error.data.appErrorCode && inactiveErrorCodes.includes(error.data.appErrorCode)) {
                this.pass = {
                    state: PASS_STATE_INACTIVE,
                    error: error.data,
                };
            } else if (error.data.appErrorCode === PASS_ERROR_CODE.HAS_NEW_PASS) {
                this.clearCurrentToken({ passUri: this.passUri });
                location.reload();
            } else {
                this.notifyError(error.message);
            }
        },

        async handleVerificationSuccess() {
            await this.loadPassData();
        },

        handleApprove(pass) {
            this.pass = pass;
        },
    },
};
</script>

<style scoped></style>
