<template>
    <div
        class="select"
        :class="{
            'select--multiple': multiple,
        }"
    >
        <!-- :dropdown-should-open="() => true" -->
        <v-select
            ref="baseSelect"
            v-bind="$attrs"
            :class="adaptive ? 'hidden md:block' : 'block'"
            :placeholder="buttonLabel"
            :options="loadedOptions"
            :getOptionKey="getOptionKey"
            :getOptionLabel="getOptionLabel"
            :modelValue="modelValue"
            :clearable="clearable"
            :multiple="multiple"
            :disabled="disabled"
            :reduce="option => (objectMode ? option : getOptionKey(option))"
            @update:modelValue="handleInput"
            @search="query => (searchQuery = query)"
            @open="onSelectOpen"
            @close="onSelectClose"
        >
            <template #list-footer>
                <li
                    v-show="loadedOptions.length && lazy && hasMore"
                    ref="load"
                    class="text-center"
                >
                    {{ $t('app.content_loading') }}
                </li>
            </template>

            <template #no-options>
                {{ $t('select.no_results') }}
            </template>
        </v-select>

        <div :class="adaptive ? 'block md:hidden' : 'hidden'">
            <div
                v-if="multiple && !empty"
                class="select__selected-items-wrapper"
            >
                <label class="label mb-0">
                    <!-- @slot Selected assets label -->
                    <slot
                        name="selected-label"
                        :count="innerValue.length"
                    >
                        {{ selectedLabel }}
                    </slot>
                </label>
                <button
                    type="button"
                    class="underline font-ffdin font-medium btn-secondary text-md2 pl-2 py-2"
                    :disabled="disabled"
                    @click="opened = true"
                >
                    {{ $t('select.change') }}
                </button>
            </div>

            <!-- @slot Header in adaptive version-->
            <slot name="adaptive-header" />

            <ul
                v-if="multiple && !empty"
                class="select__selected-items"
            >
                <li
                    v-for="(option, index) in selectedOptions"
                    :key="index"
                    class="select__selected-item"
                >
                    {{ getOptionLabel(option) }}
                </li>
            </ul>

            <button
                v-if="!multiple && !empty"
                type="button"
                class="select__selected-item-wrapper"
                :disabled="disabled"
                @click="opened = true"
            >
                <span class="text-xl leading-4 h-6 font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {{ getOptionLabel(selectedOptions[0]) }}
                </span>
                <span class="font-ffdin font-medium btn-secondary text-md2">
                    {{ $t('select.change') }}
                </span>
            </button>

            <button
                v-if="empty"
                type="button"
                class="btn btn-secondary w-full"
                :disabled="disabled"
                @click="opened = true"
            >
                {{ preparedButtonLabel }}
            </button>

            <transition
                v-if="opened"
                appear
                name="slide"
            >
                <div class="select__slide-panel">
                    <div class="slide-panel absolute right-0 top-0 h-full shadow-2xl flex flex-col px-6 py-4">
                        <div class="nav">
                            <icon
                                name="arrow-left"
                                class="md:hidden flex-shrink-0 h-6 w-6 mr-3 text-gray-800 cursor-pointer"
                                @click="onSlideClose"
                            />
                            <div class="page-title flex-grow">{{ title }}</div>
                            <icon
                                name="close"
                                class="z-50 h-6 w-6 cursor-pointer"
                                @click="onSlideClose"
                            />
                        </div>
                        <div
                            class="select__body"
                            :class="{ 'overflow-auto': !emptySearchResult }"
                        >
                            <div class="search">
                                <icon
                                    name="search"
                                    class="absolute top-3.5 left-4 w-3 h-3 text-gray-400"
                                />
                                <input
                                    v-model="searchQuery"
                                    type="text"
                                    class="input"
                                    :placeholder="searchPlaceholderLabel"
                                />
                                <span
                                    v-if="searchQuery"
                                    class="clear"
                                    @click="searchQuery = ''"
                                >
                                    <icon
                                        name="close"
                                        class="w-3 h-3"
                                    />
                                </span>
                            </div>

                            <!-- @slot Slide panel header -->
                            <slot
                                name="slide-panel-header"
                                v-bind="{ options: filteredOptions }"
                            />

                            <ul
                                ref="optionsWrapper"
                                class="options"
                            >
                                <li
                                    v-for="option of filteredOptions"
                                    :key="getOptionKey(option)"
                                    class="option"
                                    :class="{ 'option--selected': isOptionSelected(option) }"
                                    @click="selectOption(option)"
                                >
                                    {{ getOptionLabel(option) }}
                                    <icon
                                        v-if="multiple || isOptionSelected(option)"
                                        name="checkmark"
                                        class="check-icon"
                                    />
                                </li>
                                <li
                                    v-show="lazy && hasMore"
                                    ref="mobileLoad"
                                    class="text-center"
                                >
                                    {{ $t('app.content_loading') }}
                                </li>
                            </ul>

                            <div
                                v-if="emptySearchResult"
                                class="select__empty-result"
                            >
                                {{ $t('select.no_results') }}
                            </div>

                            <div
                                v-if="multiple && hasOptions"
                                class="flex"
                            >
                                <button
                                    type="button"
                                    class="btn btn-tertiary w-full"
                                    :disabled="empty"
                                    @click="handleResetAll"
                                >
                                    {{ $t('select.reset_all') }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary w-full"
                                    :disabled="empty && !clearable"
                                    @click="handleApply"
                                >
                                    {{ $t('select.apply') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import vSelect from 'vue-select';
import { debounce } from 'lodash-es';
import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    name: 'SelectInput',

    components: { Icon, vSelect },

    mixins: [NotifyMixin],

    props: {
        /**
         * Side panel title
         */
        title: {
            type: String,
        },

        /**
         * Label text for select button
         */
        buttonLabel: {
            type: String,
            required: false,
        },

        /**
         * Search input placeholder
         */
        searchPlaceholder: {
            type: String,
            required: false,
        },

        /**
         * Switch input mode on screen size change
         */
        adaptive: {
            type: Boolean,
            default: true,
        },

        /**
         * Presets the selected options value
         * @type {Object|Array|String|Number}
         */
        modelValue: {
            type: [Number, String, Array, Object],
        },

        /**
         * Array of available options
         */
        options: {
            type: Array,
            required: false,
        },

        /**
         * If the control should be disabled
         */
        disabled: {
            type: Boolean,
        },

        /**
         * Allows to reset value
         */
        clearable: {
            type: Boolean,
            default: true,
        },

        /**
         * Equivalent to the `multiple` attribute on a `<select>` input
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Should the value be option object or just key
         */
        objectMode: {
            type: Boolean,
            default: false,
        },

        /**
         * Key to find options
         */
        trackBy: {
            type: String,
            default: 'key',
        },

        /**
         * Label to look for in option object
         */
        labelKey: {
            type: String,
            default: 'value',
        },

        /**
         * Should options be loaded partially while scrolling the options list
         */
        lazy: {
            type: Boolean,
        },

        /**
         * Data provider to fetch options
         */
        dataProvider: {
            type: Object,
            required: false,
        },

        /**
         * Name of a resource used by the data provider
         */
        resource: {
            type: String,
            required: false,
        },

        /**
         * Additional parameters that could be sent with options requests
         */
        requestParams: {
            type: Object,
            required: false,
        },

        /**
         * Data mapping function applied to each item from a response
         */
        itemMapper: {
            type: Function,
            default: option => option,
        },

        /**
         * Data mapping function applied to each response
         */
        responseMapper: {
            type: Function,
            default: response => (response.items ? response.items.map(({ token, value }) => ({ token, ...value })) : response),
            required: false,
        },
    },

    emits: [
        /**
         * Emitted on value/values change
         */
        'update:modelValue',
        /**
         * Emitted on options list update
         */
        'options-loaded',
        /**
         * Emitted on adaptive state change
         */
        'adaptive-state-updated',
    ],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            opened: false,
            searchQuery: '',
            innerValue: null,
            adaptiveState: false,

            // infinite scroll
            loading: false,
            hasMore: true,
            loadedOptions: [],
            ITEMS_LIMIT: 20,
            sidePanelObserver: null,

            // for vue-select
            dropdownObserver: null,
        };
    },

    computed: {
        filteredOptions() {
            let result = this.loadedOptions;

            if (!this.multiple && this.innerValue) {
                result = [
                    this.selectedOptions[0],
                    ...result.filter(o => this.getOptionKey(o) !== this.getOptionKey(this.selectedOptions[0])),
                ];
            }

            if (!this.lazy && this.searchQuery) {
                result = result.filter(({ value }) => value.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }

            return result;
        },

        selectedOptions() {
            return (this.innerValue == null ? [] : this.multiple ? this.innerValue : [this.innerValue])
                .map(value => this.getOptionByValue(value))
                .filter(o => o);
        },

        hadInitialLoad() {
            return this.loadedOptions.length || !this.hasMore;
        },

        preparedButtonLabel() {
            return this.buttonLabel || this.t('select.select');
        },

        empty() {
            return this.multiple ? this.innerValue.length === 0 : !this.innerValue;
        },

        selectedLabel() {
            return `${this.t('select.selected', {
                count: this.innerValue.length,
                itemType: this.t('select.item', this.innerValue.length),
            })}:`;
        },

        searchPlaceholderLabel() {
            return this.searchPlaceholder || this.t('select.search');
        },

        hasOptions() {
            return !this.searchQuery && this.filteredOptions.length;
        },

        emptySearchResult() {
            return this.searchQuery && !this.filteredOptions.length;
        },
    },

    created() {
        if (this.lazy) {
            this.dropdownObserver = new IntersectionObserver(this.handleScroll);
            this.sidePanelObserver = new IntersectionObserver(this.handleScroll);
        }

        this.initializeWatchers();
    },

    mounted() {
        this.debounceCheckAdapativeState = debounce(this.checkAdaptiveState, 30);
        window.addEventListener('resize', this.debounceCheckAdapativeState);
        this.checkAdaptiveState();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.debounceCheckAdapativeState);
    },

    methods: {
        getOptionKey(option) {
            return option?.[this.trackBy];
        },

        getOptionLabel(option) {
            if (typeof option === 'object') {
                return option?.[this.labelKey] ?? '';
            }

            return option;
        },

        getOptionByValue(value) {
            if (this.objectMode) {
                return value;
            }

            return this.loadedOptions.find(option => this.getOptionKey(option) === value);
        },

        getValueByOption(option) {
            return this.objectMode ? option : this.getOptionKey(option);
        },

        isOptionSelected(option) {
            return this.selectedOptions.findIndex(selectedOption => this.getOptionKey(selectedOption) === this.getOptionKey(option)) >= 0;
        },

        isOptionDisabled(option) {
            return option.$isDisabled;
        },

        selectOption(option) {
            if (this.isOptionDisabled(option)) {
                return;
            }

            const value = this.isOptionSelected(option) ? null : this.getValueByOption(option);

            if (this.multiple) {
                if (value) {
                    this.innerValue = [...this.innerValue, value];
                } else {
                    this.innerValue = this.selectedOptions
                        .filter(selectedOption => this.getOptionKey(selectedOption) !== this.getOptionKey(option))
                        .map(this.getValueByOption);
                }
            } else {
                if (!value && !this.clearable) return;

                this.handleInput(value);
                this.innerValue = value;
                this.opened = false;
            }
        },

        handleApply() {
            this.handleInput(this.innerValue);
            this.opened = false;
        },

        handleResetAll() {
            this.innerValue = this.multiple ? [] : null;
        },

        onSlideClose() {
            if (this.multiple) {
                this.innerValue = this.modelValue;
            }

            this.searchQuery = '';
            this.opened = false;
        },

        handleScroll([{ isIntersecting }]) {
            if (isIntersecting) {
                this.loadNextPage();
            }
        },

        handleInput(value) {
            this.$emit('update:modelValue', value);
        },

        loadItems(options = {}) {
            const params = Object.assign({}, options, this.requestParams);

            if (this.searchQuery) {
                params.search = this.searchQuery;
            }

            if (this.dataProvider) {
                return this.dataProvider.getList(this.resource, params).then(response => {
                    this.$emit('options-loaded', response.totalSize);

                    return response;
                });
            } else if (!this.options) {
                throw new Error('Dropdown: either `options` or `dataProvider` must be specified');
            }
        },

        loadOptions() {
            if (this.dataProvider) {
                this.loading = true;

                this.loadItems({ limit: this.ITEMS_LIMIT })
                    .then(response => {
                        this.loadedOptions = this.responseMapper(response).map(this.itemMapper);
                        this.hasMore = this.loadedOptions.length >= this.ITEMS_LIMIT;
                    })
                    .catch(e => {
                        this.notifyError(e.message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.loadedOptions = this.options.map(this.itemMapper);
                this.$emit('options-loaded', this.loadedOptions.length);
            }
        },

        loadNextPage() {
            if (!this.hasMore || this.loading) return;

            this.loading = true;

            this.loadItems({
                token: this.loadedOptions[this.loadedOptions.length - 1].token,
                limit: this.ITEMS_LIMIT,
            })
                .then(response => {
                    const options = this.responseMapper(response).map(this.itemMapper);
                    this.hasMore = options.length >= this.ITEMS_LIMIT;

                    this.loadedOptions = [...this.loadedOptions, ...options];
                })
                .catch(e => {
                    this.notifyError(e.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        // vue-select infinite scroll
        async onSelectOpen() {
            if (!this.lazy || !this.hasMore) return;

            await this.$nextTick();
            this.dropdownObserver.observe(this.$refs.load);
        },

        onSelectClose() {
            if (this.dropdownObserver) {
                this.dropdownObserver.disconnect();
            }
        },

        initializeWatchers() {
            this.$watch(() => [this.options, this.dataProvider, this.resource, this.requestParams], this.loadOptions, {
                immediate: true,
            });

            this.$watch('searchQuery', debounce(this.loadOptions, 500), {
                immediate: true,
            });

            this.$watch(
                'modelValue',
                function (value) {
                    this.innerValue = value;
                },
                {
                    immediate: true,
                }
            );

            this.$watch('opened', async function (value) {
                if (!this.lazy) return;

                if (value) {
                    await this.$nextTick();
                    this.sidePanelObserver.observe(this.$refs.mobileLoad);
                } else if (this.sidePanelObserver) {
                    this.sidePanelObserver.disconnect();
                }
            });

            this.$watch('adaptiveState', function (value) {
                this.$emit('adaptive-state-updated', value);
            });
        },

        checkAdaptiveState() {
            const adaptiveState = window.getComputedStyle(this.$refs.baseSelect.$el).display === 'none';
            this.adaptiveState = adaptiveState;
        },
    },
};
</script>

<style scoped>
.nav {
    @apply flex items-center mb-4;
}

.select__slide-panel {
    @apply fixed z-50 top-0 bottom-0 left-0 w-full;
}

.select__selected-items-wrapper {
    @apply flex justify-between items-center;
}

.select__selected-item-wrapper {
    @apply flex items-center justify-between w-full h-14 px-4 border rounded-lg;
}

.select__selected-item {
    @apply text-gray-700 font-sofia text-md py-3 border-b;
}

.select__body {
    @apply flex flex-col;
}

.btn-secondary:hover {
    background-color: transparent;
}

.slide-panel {
    @apply overflow-hidden w-full bg-white;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-out;
}

.slide-enter-active .backdrop,
.slide-leave-active .backdrop {
    transition: all 0.3s ease-out;
}

.slide-enter-from .backdrop,
.slide-leave-to .backdrop {
    opacity: 0;
}

.slide-enter-active .slide-panel,
.slide-leave-active .slide-panel {
    transition: all 0.3s ease-out;
    transform: translateX(0);
}

.slide-enter-from .slide-panel,
.slide-leave-to .slide-panel {
    transform: translateX(100%);
}

.search {
    @apply relative mb-2;
}

.search .input {
    @apply h-10 px-10 pt-2 font-sofia text-base;
}

.search .clear {
    @apply absolute right-3 top-3 z-50 w-4 h-4 cursor-pointer flex items-center justify-center bg-gray-200 rounded-full;
}

.options {
    @apply font-sofia font-normal flex-grow overflow-auto;
}

.option {
    @apply flex justify-between items-center relative pt-2 pb-3 text-gray-600 cursor-pointer border-b;
}

.option:last-child {
    @apply border-none;
}

.select .option {
    @apply px-4;
}

.select:not(.select--multiple) .option:nth-child(odd) {
    @apply bg-purple-200;
}
.select:not(.select--multiple) .option.option--selected {
    @apply bg-purple-500 text-white;
}

.check-icon {
    @apply ml-2;
    min-width: 0.8125rem;
    width: 0.8125rem;
    height: 0.8125rem;
}

.select--multiple .check-icon {
    @apply text-white border rounded-sm border-gray-500;
}

.select:not(.select--multiple) .check-icon {
    @apply w-5 h-5;
}

.select--multiple .option--selected .check-icon {
    @apply bg-purple-500 border-purple-500;
}

.select__empty-result {
    @apply text-sm text-gray-400;
}
</style>
