<template>
    <div class="relative">
        <card
            class="location-card"
            :class="{ pending: state === DEVICE_STATE_REQUEST_SENT }"
            @click="handleLocationCardClick"
        >
            <div class="flex flex-col justify-between flex-1">
                <div class="text-base uppercase font-bold mb-1">
                    {{ device.locationName }}
                </div>
                <div class="font-normal text-md text-gray-500">
                    {{ lockStateText }}
                </div>
                <p
                    v-if="yaleLockHelperMessage"
                    class="text-gray-450 font-light text-xs w-10/12"
                    v-html="t('pass.yale_lock_helper_message')"
                ></p>
            </div>

            <div
                class="state-icon-wrapper"
                :class="iconCircleClasses"
            >
                <icon
                    :name="iconName"
                    class="w-4 h-4"
                />
            </div>
        </card>

        <div
            v-if="state === DEVICE_STATE_REQUEST_SENT"
            class="shadow-card-wrapper"
        >
            <card
                class="location-card shadow-card"
                :style="getShadowCardStyle()"
                @click="handleLocationCardClick"
            >
                <div class="flex flex-col justify-between flex-1">
                    <div class="text-base uppercase font-bold mb-1">
                        {{ device.locationName }}
                    </div>
                    <div class="font-normal text-md">
                        {{ lockStateText }}
                    </div>
                </div>

                <div class="state-icon-wrapper">
                    <icon
                        :name="iconName"
                        class="w-4 h-4"
                    />
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import Card from '@/components/ui/Card';
import Icon from '@/components/ui/Icon';
import { useI18n } from 'vue-i18n';
import {
    DEVICE_STATE_ERROR,
    DEVICE_STATE_OPENED,
    DEVICE_STATE_PRISTINE,
    DEVICE_STATE_REQUEST_SENT,
    DEVICE_STATE_FAILED,
    LOCK_VENDORS,
    PASS_ERROR_CODE,
} from '../../constants/passes';

export default {
    components: { Icon, Card },

    props: {
        device: {
            type: Object,
            required: true,
        },
    },

    emits: ['lockOpen', 'reload'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            DEVICE_STATE_REQUEST_SENT,
            state: DEVICE_STATE_PRISTINE,
            lockPollingIntervalId: undefined,
            pollingCallsCount: 0, // 0 - 5
            pollingErrorMessage: undefined,
            commandSequenceId: undefined,
            LOCK_VENDORS,
        };
    },

    computed: {
        lockStateText() {
            switch (this.state) {
            case DEVICE_STATE_PRISTINE:
                return this.t('pass.tap_to_unlock');
            case DEVICE_STATE_REQUEST_SENT:
                return this.t('pass.sending_request');
            case DEVICE_STATE_OPENED:
                return this.t('pass.authorization_sent');
            case DEVICE_STATE_ERROR:
                return this.pollingErrorMessage;
            default:
                return '';
            }
        },

        iconName() {
            switch (this.state) {
            case DEVICE_STATE_PRISTINE:
            case DEVICE_STATE_REQUEST_SENT:
                return 'lock';
            case DEVICE_STATE_OPENED:
                return 'checkmark_2';
            case DEVICE_STATE_ERROR:
                return 'close_2';
            default:
                return '';
            }
        },

        iconCircleClasses() {
            switch (this.state) {
            case DEVICE_STATE_PRISTINE:
            case DEVICE_STATE_REQUEST_SENT:
                return 'bg-purple-200 border-purple-300 text-purple-600';
            case DEVICE_STATE_OPENED:
                return 'bg-green-200 border-green-300 text-green-600';
            case DEVICE_STATE_ERROR:
                return 'bg-red-200 border-red-300 text-red-600';
            default:
                return '';
            }
        },

        yaleLockHelperMessage() {
            return this.device.vendor === LOCK_VENDORS.YALE && this.state === DEVICE_STATE_OPENED;
        },

        errorCodeValues() {
            return Object.values(PASS_ERROR_CODE);
        },
    },

    watch: {
        state(state) {
            switch (state) {
            case DEVICE_STATE_OPENED:
                this.$emit('lockOpen');
            }
        },
    },

    methods: {
        handleLocationCardClick() {
            switch (this.state) {
            case DEVICE_STATE_PRISTINE:
                this.state = DEVICE_STATE_REQUEST_SENT;
                this.startPolling();
                break;
            case DEVICE_STATE_REQUEST_SENT:
                break;
            case DEVICE_STATE_OPENED:
            case DEVICE_STATE_ERROR:
                this.state = DEVICE_STATE_PRISTINE;
                break;
            }
        },

        async startPolling() {
            try {
                const { commandSequenceId, status } = await this.$passDataProvider.start('pulse', { id: this.device.id });
                this.commandSequenceId = commandSequenceId;
                this.state = status;

                if (this.state === DEVICE_STATE_OPENED) {
                    setTimeout(() => {
                        this.state = DEVICE_STATE_PRISTINE;
                    }, 3000);
                    return;
                }

                this.lockPollingIntervalId = setInterval(() => {
                    this.pollingCallsCount++;
                    this.$passDataProvider
                        .getStatus('pulse', { lockId: this.device.id, commandSequenceId: this.commandSequenceId })
                        .then(state => {
                            if (state === DEVICE_STATE_FAILED) {
                                throw new Error(this.t('pass.errors.something_went_wrong'));
                            }

                            this.state = state;
                        })
                        .catch(error => {
                            this.state = DEVICE_STATE_ERROR;
                            this.pollingErrorMessage = this.getErrorMessageText(error);
                            setTimeout(() => {
                                this.state = DEVICE_STATE_PRISTINE;
                                this.pollingErrorMessage = undefined;
                            }, 3000);
                        })
                        .finally(() => {
                            if (this.pollingCallsCount >= 6 || this.state !== DEVICE_STATE_REQUEST_SENT) {
                                clearInterval(this.lockPollingIntervalId);
                                this.pollingCallsCount = 0;

                                if (this.state === DEVICE_STATE_REQUEST_SENT) {
                                    this.state = DEVICE_STATE_PRISTINE;
                                } else if (this.state === DEVICE_STATE_OPENED) {
                                    setTimeout(() => {
                                        this.state = DEVICE_STATE_PRISTINE;
                                    }, 3000);
                                }
                            }
                        });
                }, 5 * 1000);
            } catch (error) {
                if (this.errorCodeValues.includes(error.data.appErrorCode)) {
                    this.$emit('reload');
                }
                this.state = DEVICE_STATE_ERROR;
                this.pollingErrorMessage = this.getErrorMessageText(error);
                setTimeout(() => {
                    this.state = DEVICE_STATE_PRISTINE;
                    this.pollingErrorMessage = undefined;
                }, 3000);
            }
        },

        getShadowCardStyle() {
            const card = document.querySelector('.location-card');
            return card
                ? {
                    width: `${card.offsetWidth}px`,
                }
                : {};
        },

        getErrorMessageText(error) {
            if (error?.data?.appErrorCode === PASS_ERROR_CODE.NOT_FOUND) {
                return this.t('pass.errors.pass_failed');
            } else if (error?.data?.appErrorCode === 'common.internal_server_error' && error.message.includes('timeout')) {
                return this.t('pass.errors.something_went_wrong');
            }

            return error.message;
        },
    },
};
</script>

<style scoped>
.location-card {
    @apply flex items-center justify-between px-6 py-5 mb-4;
}
.location-card:deep(hr) {
    @apply hidden;
}

.state-icon-wrapper {
    @apply flex items-center justify-center w-11 h-11 border rounded-full;
}

.shadow-card-wrapper {
    @apply absolute top-0 left-0 w-full overflow-hidden;
    animation: loading 30s linear infinite;
}

.shadow-card {
    @apply bg-purple-600;
}

.shadow-card * {
    @apply text-white border-white bg-purple-600;
}

@keyframes loading {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
</style>
