<template>
    <FinalForm
        ref="form"
        class="w-full"
        :submit="submit"
        :initialValues="initialValues"
    >
        <template v-slot="props">
            <form
                class="h-full"
                @submit.prevent="e => handleSubmit(e, props.handleSubmit)"
            >
                <slot
                    name="default"
                    v-bind="props"
                ></slot>
            </form>
        </template>
    </FinalForm>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import InitializeFormMixin from '@/mixins/InitializeFormMixin';
import EventBus from '@/utils/EventBus';

export default {
    name: 'Form',
    components: {
        FinalForm,
    },

    mixins: [InitializeFormMixin],
    props: {
        submit: {
            type: Function,
            required: true,
        },

        initialValues: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        finalForm() {
            return this.$refs.form.finalForm;
        },

        formState() {
            return this.$refs.form.formState;
        },
    },

    mounted() {
        EventBus.on('locale-changed', this.restart);
    },

    beforeUnmount() {
        EventBus.off('locale-changed', this.restart);
    },

    methods: {
        async handleSubmit(e, submit) {
            await submit();

            const el = document.querySelector('.field-error');

            if (el) {
                el.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
        },

        restart() {
            this.$refs.form.finalForm.restart();
        },
    },
};
</script>
