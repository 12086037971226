<template>
    <div class="pt-6 flex flex-col h-full">
        <div class="page-title overflow-visible mb-6">
            {{ displayTitle }}
        </div>

        <div class="form-field">
            <div class="label">{{ t('pass.access_to') }}</div>
            <div class="data">{{ t('pass.n_locations', pass.remoteUnlockDevices.length) }}</div>
        </div>

        <div class="form-field">
            <div
                v-if="countdownEndTime"
                class="label"
            >
                {{ t('pass.due_date') }}
            </div>
            <div
                v-else
                class="label"
            >
                {{ t('pass.expiration_date') }}
            </div>
            <div class="data">
                {{ expirationDate }}
            </div>
        </div>

        <div
            v-if="pass?.durationInMinutes"
            class="form-field"
        >
            <div
                v-if="countdownEndTime"
                class="label"
            >
                {{ t('pass.expires_in') }}
            </div>
            <div
                v-else
                class="label"
            >
                {{ t('pass.limit_of_usage') }}
            </div>
            <div class="data">
                <div
                    v-if="countdownDuration > 0"
                    class="text-purple-500"
                >
                    {{
                        `${countdownHours} ${t('pass.hours_short')} : ${countdownMinutes} ${t(
                            'pass.minutes_short'
                        )} : ${countdownSeconds} ${t('pass.seconds_short')}`
                    }}
                </div>
                <template v-else>
                    {{ limitOfUsageString }}
                </template>
            </div>
        </div>

        <div
            v-if="pass.message"
            class="form-field mb-6"
        >
            <div class="label">{{ messageLabel }}</div>
            <div class="infobox bg-white text-black font-sofia font-normal text-md">
                {{ pass.message }}
            </div>
        </div>

        <div class="flex-grow bg-gray-150 p-6 -mx-6 -mb-4">
            <div class="infobox bg-gray-100 flex items-baseline mb-4">
                <icon
                    name="info"
                    class="w-3.5 h-3.5 mr-4"
                />
                <div class="flex-1 text-black text-md2">To open the door, press the corresponding button.</div>
            </div>

            <DeviceCard
                v-for="device of pass.remoteUnlockDevices"
                :key="device.id"
                :device="device"
                @lockOpen="handleLockOpen"
                @reload="$emit('reload')"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import DeviceCard from './DeviceCard.vue';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';
import { PASS_TYPES } from '../../constants/passes';

export default {
    components: { DeviceCard, Icon },

    props: {
        pass: {
            type: Object,
            required: true,
        },
    },

    emits: ['reload'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            countdownEndTime: undefined,
            /** in milliseconds */
            countdownDuration: 0,
            countdownIntervalId: undefined,
        };
    },

    computed: {
        ...mapGetters({
            currentTimezone: 'pass/currentTimezone',
            passType: 'pass/passType',
            apartmentName: 'pass/apartmentName',
        }),

        limitOfUsageString() {
            if (!this.pass.durationInMinutes) {
                return '-';
            }

            const hours = Math.floor(this.pass.durationInMinutes / 60);
            const minutes = this.pass.durationInMinutes % 60;

            return (hours ? hours + this.t('pass.hours_short') + ' ' : '') + minutes + this.t('pass.minutes_short');
        },

        countdownHours() {
            return Math.floor(this.countdownDuration / (1000 * 60 * 60));
        },

        countdownMinutes() {
            return Math.floor((this.countdownDuration % (1000 * 60 * 60)) / (1000 * 60));
        },

        countdownSeconds() {
            return Math.floor((this.countdownDuration % (1000 * 60)) / 1000);
        },

        expirationDate() {
            const date = this.checkFormatDate(this.countdownEndTime || this.pass.to);
            return moment(date).tz(this.currentTimezone).format('MMM D, YYYY [at] hh:mm A');
        },

        displayTitle() {
            if ([PASS_TYPES.VENDOR, PASS_TYPES.RESIDENT].includes(this.passType)) {
                return this.t('pass.your_pass_to', { name: this.apartmentName });
            }

            if (this.passType === PASS_TYPES.GUEST) {
                return this.t('pass.your_self_tour', { name: this.apartmentName });
            }

            if (this.pass.hostUnit) {
                return this.t('pass.your_pass_from', { name: this.pass.hostUnit });
            }

            return this.t('pass.your_pass');
        },

        messageLabel() {
            return this.passType === PASS_TYPES.QUEXT ? this.t('pass.host_left_message') : this.t('pass.property_left_message');
        },
    },

    created() {
        if (this.pass.expiredAt) {
            this.countdownEndTime = moment(this.checkFormatDate(this.pass.expiredAt)).toDate();
            const currentTime = moment.utc().toDate();
            this.countdownDuration = moment(this.countdownEndTime).diff(moment(currentTime));
            if (this.countdownDuration > 0) {
                this.startExpirationCountdown();
            }
        }
    },

    methods: {
        checkFormatDate(date) {
            const checkFormatRegex = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?$/.test(date);
            if (checkFormatRegex) {
                date = `${date}Z`;
            }
            return date;
        },

        handleLockOpen() {
            if (!this.countdownIntervalId) {
                // set countdownDuration value
                if (this.pass.durationInMinutes) {
                    this.countdownEndTime = moment(new Date()).add(this.pass.durationInMinutes, 'minutes').toDate();
                    this.countdownDuration = this.pass.durationInMinutes * 60 * 1000;

                    this.startExpirationCountdown();
                }
            }
        },

        startExpirationCountdown() {
            this.countdownIntervalId = setInterval(() => {
                this.countdownDuration -= 1000;
                if (this.countdownDuration <= 0) {
                    clearInterval(this.countdownIntervalId);
                }
            }, 1000);
        },
    },
};
</script>

<style scoped></style>
