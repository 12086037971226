<template>
    <div class="flex flex-col items-center justify-center h-full">
        <PassIcon
            classes="opacity-25"
            :passType="error?.passType"
        />

        <div class="font-ffdin font-bold text-base uppercase mb-3">
            {{ t(`pass.errors.${errorTitle}`) }}
        </div>

        <div class="font-sofia font-normal text-md text-gray-500 text-center">
            {{ errorDescription }}
        </div>

        <CallUs
            position="bottom"
            :error="error"
        />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import PassIcon from '../../components/pass/common/PassIcon';
import CallUs from '../../components/pass/common/CallUs';
import { PASS_TYPES, PASS_ERROR_CODE } from '../../constants/passes';

export default {
    components: { PassIcon, CallUs },

    props: {
        error: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    computed: {
        isVendorPasses() {
            const selfTourPassTypes = [PASS_TYPES.GUEST, PASS_TYPES.RESIDENT, PASS_TYPES.VENDOR];
            return this.error?.passType && selfTourPassTypes.includes(this.error?.passType);
        },

        isSelfTour() {
            return this.error?.passType && this.error?.passType === PASS_TYPES.GUEST;
        },

        errorTitle() {
            switch (this.error.appErrorCode) {
            case PASS_ERROR_CODE.NOT_STARTED_YET:
                return this.isVendorPasses ? 'tooEarly' : 'hello';

            case PASS_ERROR_CODE.NOT_FOUND:
            case PASS_ERROR_CODE.NOT_AVAILABLE:
                return this.isSelfTour ? 'invalidSelfTour' : this.isVendorPasses ? 'invalidPass' : 'sorry';

            case PASS_ERROR_CODE.EXPIRED:
                return this.isSelfTour ? 'expiredSelfTour' : this.isVendorPasses ? 'expiredPass' : 'sorry';

            default:
                return 'hello';
            }
        },

        errorDescription() {
            switch (this.error.appErrorCode) {
            case PASS_ERROR_CODE.NOT_FOUND:
            case PASS_ERROR_CODE.NOT_AVAILABLE:
                return this.t(`pass.errors.${this.isVendorPasses ? 'pass_not_available' : 'pass_failed'}`);

            default:
                return this.error.message;
            }
        },
    },
};
</script>
