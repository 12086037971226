import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export const useCountryCodes = () => {
    const { locale } = useI18n();

    const countries = computed(() => {
        const regionNames = new Intl.DisplayNames([locale.value], { type: 'region' });
        return getCountries()
            .map(code => ({
                name: regionNames.of(code),
                prefix: code.toUpperCase(),
                dialCode: getCountryCallingCode(code),
            }))
            .sort((a, b) => a.prefix.localeCompare(b.prefix, locale.value));
    });

    return countries.value;
};
